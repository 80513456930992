// react imports
import React, { useState } from "react";

// third-party imports
import {
  Select,
  MenuItem,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
} from "@mui/material";

const MultiselectDropdown = ({
  options = [
    {
      label: "Option 1",
      value: "option_1",
    },
    {
      label: "Option 2",
      value: "option_2",
    },
  ],
  onSelectChange,
  isDisabled = false,
  defaultValue = [],
  placeholder = "Select",
  minWidth = "100%",
  maxWidth = "auto",
  isMultipleSelect = false,
  maxAllowedSelections = 3,
  fontSize = "13px",
  borderRadius = "8px",
  height = "38px",
  backgroundColor = "white",
}) => {
  const [focused, setFocused] = useState(false);
  const [selectedValues, setSelectedValues] = useState(defaultValue);

  const handleChange = (event) => {
    const value = event.target.value;
    if (isMultipleSelect) {
      if (value?.length <= maxAllowedSelections) {
        setSelectedValues(value);
        onSelectChange(value);
      }
    } else {
      setSelectedValues(value);
      onSelectChange(value);
    }
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        minWidth: minWidth,
        maxWidth: maxWidth,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
      }}
    >
      <InputLabel
        shrink={focused || selectedValues?.length > 0}
        sx={{
          fontSize: fontSize,
          color: "#00000099",
          transition: "all 0.2s ease-in-out",
          left: focused ? "0px" : "12px",
          transform: "translateY(10px)",
          "&.MuiInputLabel-shrink": {
            fontSize: "16px",
            color: "black",
            transform: "translateY(-8px) translateX(13px) scale(0.67)",
            left: "0px",
          },
        }}
      >
        {placeholder}
      </InputLabel>
      <Select
        multiple={isMultipleSelect}
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={placeholder} />}
        disabled={isDisabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        sx={{
          width: "100%",
          minWidth: minWidth,
          maxWidth: maxWidth,
          fontSize,
          height: selectedValues?.length > 0 ? "auto" : height,
          backgroundColor,
          borderRadius,
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          "& .MuiSelect-select": {
            padding: "4px 12px",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
              maxWidth: maxWidth,
              overflowY: "auto",
            },
          },
        }}
        renderValue={(selected) =>
          isMultipleSelect ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={options.find((opt) => opt?.value === value)?.label}
                  sx={{ margin: 0.5, fontSize, height: "24px" }}
                />
              ))}
            </div>
          ) : (
            options.find((opt) => opt?.value === selected)?.label || ""
          )
        }
      >
        {options.map((option, idx) => (
          <MenuItem
            key={option?.value}
            value={option?.value}
            disabled={
              isMultipleSelect &&
              selectedValues?.length >= maxAllowedSelections &&
              !selectedValues?.includes(option?.value)
            }
            divider={idx === 4}
            sx={{
              fontSize,
              width: "100%",
              wordWrap: "break-word",
              whiteSpace: "normal",
              maxWidth: "100%",
              padding: "6px",
            }}
          >
            <Checkbox
              size="small"
              checked={selectedValues?.includes(option?.value)}
            />
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              {option?.icon && (
                <img style={{ width: "16px" }} src={option?.icon} />
              )}
              <p>{option?.label}</p>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiselectDropdown;
