// in-app imports

// About-us
import randomGif from "../images/RandomGif.gif";
import growth from "../images/growth.gif";

// Banner
// import icici from "../images/demo-bank-color-2.png";
// import ca from "../images/power-2.png";
import yes from "../images/group_color.png";

// Empower
import geteBRC from "../images/Empower/get_eBRC.png";
import realTime from "../images/Empower/real_time.png";
import boostMargin from "../images/Empower/boost_margin.png";

// Testimonial
import user1 from "../images/testimonials/User1.png";
import user2 from "../images/testimonials/User2.png";
import user3 from "../images/testimonials/User3.png";

// Thank you
import handshake from "../images/handshake.png";
import BlunoIcon from "../images/blunoIcon.png";

// Footer
import blunologo from "../images/logo-new.png";
import InstagramIcon from "../images/SocialMedia/instagram.png";
import FacebookIcon from "../images/SocialMedia/facebook.png";
import TwitterIcon from "../images/SocialMedia/twitter.png";

//how bluno works
import homePage1 from "../images/home-page-1.png";
import homePage2 from "../images/home-page-2.png";
import homePage3 from "../images/home-page-3.png";

//bluno security card
import security1 from "../images/security-1.png";
import security2 from "../images/security-2.png";
import security3 from "../images/security-3.png";

//demo
import requestDemo from "../images/demo-request.png";

export const DATA = {
  BLUNO_USER_V2: {
    title: "Be an <span>export leader</span> for a borderless tomorrow",
    sub_title: "Tailored export compliance solutions for every business type",
    list: [
      {
        card_slug: "manufacturer",
        image: "https://static.bluno.in/landing/manufacturer.webp",
        title: "Manufacturer exporter",
        arrow: "https://static.bluno.in/landing/black-right-arrow.webp",
        description: "Breaking barriers in export compliance for manufacturing",
        details: {
          heading: "Manufacturer",
          sub_heading:
            "Breaking barriers in export compliance for manufacturing",
          icon: "https://static.bluno.in/landing/manufacturer.webp",
          list: [
            {
              title: "Key challenges",
              list: [
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-1.webp",
                  text: "High costs and complex documentation",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-2.webp",
                  text: "Manual reconciliation errors",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-3.webp",
                  text: "Completely opaque compliance process",
                },
              ],
            },
            {
              title: "What we offer ?",
              list: [
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-1.webp",
                  text: "Simplified compliance documentation with up to <span>70% cost savings</span>",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-2.webp",
                  text: "Automated reconciliation and <span>real-time tracking</span>",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-3.webp",
                  text: "<span>Buyer-specific Insights</span>",
                },
              ],
            },
          ],
          footer:
            "With <span>Bluno</span>, export compliance is no longer a hassle—it’s an opportunity to grow with confidence!",
        },
      },
      {
        card_slug: "e_commerce_exporter",
        image: "https://static.bluno.in/landing/e-commerce-exporter.webp",
        title: "E-commerce exporter",
        arrow: "https://static.bluno.in/landing/black-right-arrow.webp",
        description:
          "From Amazon to your own online store: Export compliance simplified for all e-commerce exporters",
        details: {
          heading: "E-commerce exporter entrepreneurs",
          sub_heading:
            "From Amazon to your own online store: Export compliance simplified for all e-commerce exporters",
          icon: "https://static.bluno.in/landing/e-commerce-exporter.webp",
          list: [
            {
              title: "Key challenges",
              list: [
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-1.webp",
                  text: "High bank charges",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-2.webp",
                  text: "Complex reconciliation",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-3.webp",
                  text: "Non-compliance risks",
                },
              ],
            },
            {
              title: "What we offer ?",
              list: [
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-1.webp",
                  text: "Paperless workflow with <span>fully automated reconciliation</span>",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-2.webp",
                  text: "<span>Real-time tracking</span>",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-3.webp",
                  text: "<span>Up to 70% savings</span> in compliance costs",
                },
              ],
            },
          ],
          footer:
            "<span>Bluno</span> empowers Indian e-commerce entrepreneurs to focus on scaling their business by eliminating friction in compliance processes. With Bluno, exporting is no longer a tedious task—it’s a smooth journey toward global growth.",
        },
      },
      {
        card_slug: "merchant",
        image: "https://static.bluno.in/landing/merchant.webp",
        title: "Merchant exporter",
        arrow: "https://static.bluno.in/landing/black-right-arrow.webp",
        description:
          "Simplifying compliance, amplifying merchant exporters’ success",
        details: {
          heading: "Merchant exporter",
          sub_heading:
            "Simplifying compliance, amplifying merchant exporters’ success",
          icon: "https://static.bluno.in/landing/merchant.webp",
          list: [
            {
              title: "Key challenges",
              list: [
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-1.webp",
                  text: "Complex compliance requirements across multiple products & suppliers ",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-2.webp",
                  text: "High costs & error-prone manual reconciliation",
                },
                {
                  icon: "https://static.bluno.in/landing/negative-usecase-icon-3.webp",
                  text: "Completely opaque compliance process",
                },
              ],
            },
            {
              title: "What we offer ?",
              list: [
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-1.webp",
                  text: "<span>Up to 70%</span> savings in compliance costs",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-2.webp",
                  text: "<span>Automated reconciliation</span> and real time tracking",
                },
                {
                  icon: "https://static.bluno.in/landing/positive-usecase-icon-3.webp",
                  text: "<span>Real-time analytics</span>",
                },
              ],
            },
          ],
          footer:
            "<span>Bluno</span> empowers merchant exporters to navigate complexities of compliances for multiple product lines with ease - saving time and resources while enabling global growth.",
        },
      },
    ],
    button: [
      {
        button_label: "Learn more",
        button_type: "primary",
      },
    ],
  },
  BLUNO_USER: {
    users: [
      {
        heading: "Manufacturer",
        sub_heading:
          "Tailored Export Compliance Solutions for Manufacturer Exporter",
        icon: "https://static.bluno.in/landing/manufacturer-round.webp",
        list: [
          {
            title: "Key Challenges",
            text: "",
            list: [
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
            ],
          },
          {
            title: "What we offer ?",
            text: "",
            list: [
              {
                icon: "",
                text: "Struggling to Balance Production and <span>Compliance simultaneously</span>.",
              },
              {
                icon: "",
                text: "Disrupted Cash Flow for production due to Unclaimed Incentives because of compliance.",
              },
              {
                icon: "",
                text: "Lack of in-house expertise and insufficient Resources increase compliance cost and time.",
              },
            ],
          },
        ],
        footer:
          "With <span>Bluno</span>, exporting is no longer a tedious task—it's a smooth journey toward global growth.",
        button: [
          {
            button_label: "Learn more",
            button_type: "primary",
          },
        ],
      },
      {
        heading: "Manufacturer",
        sub_heading:
          "Tailored Export Compliance Solutions for Manufacturer Exporter",
        icon: "https://static.bluno.in/landing/manufacturer-round.webp",
        list: [
          {
            title: "Key Challenges",
            text: "",
            list: [
              {
                icon: "https://static.bluno.in/landing/negative-usecase-icon-1.webp",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
            ],
          },
          {
            title: "What we offer ?",
            text: "",
            list: [
              {
                icon: "",
                text: "Struggling to Balance Production and Compliance simultaneously.",
              },
              {
                icon: "",
                text: "Disrupted Cash Flow for production due to Unclaimed Incentives because of compliance.",
              },
              {
                icon: "",
                text: "Lack of in-house expertise and insufficient Resources increase compliance cost and time.",
              },
            ],
          },
        ],
        footer:
          "With <span>Bluno</span>, exporting is no longer a tedious task—it's a smooth journey toward global growth.",
        button: [
          {
            button_label: "Learn more",
            button_type: "primary",
          },
        ],
      },
      {
        heading: "Manufacturer",
        sub_heading:
          "Tailored Export Compliance Solutions for Manufacturer Exporter",
        icon: "https://static.bluno.in/landing/manufacturer-round.webp",
        list: [
          {
            title: "Key Challenges",
            text: "",
            list: [
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
              {
                icon: "",
                text: "High bank charges",
                description:
                  "Banks charge INR 500 -1,500 per export bill, eating into profits on small shipments and increasing compliance cost",
              },
            ],
          },
          {
            title: "What we offer ?",
            text: "",
            list: [
              {
                icon: "",
                text: "Struggling to Balance Production and Compliance simultaneously.",
              },
              {
                icon: "",
                text: "Disrupted Cash Flow for production due to Unclaimed Incentives because of compliance.",
              },
              {
                icon: "",
                text: "Lack of in-house expertise and insufficient Resources increase compliance cost and time.",
              },
            ],
          },
        ],
        footer:
          "With <span>Bluno</span>, exporting is no longer a tedious task—it's a smooth journey toward global growth.",
        button: [
          {
            button_label: "Learn more",
            button_type: "primary",
          },
        ],
      },
      // {
      //   heading: "",
      //   sub_heading: "",
      //   icon: "",
      //   list: [
      //     {
      //       title: "",
      //       text: "",
      //     },
      //     {
      //       title: "",
      //       text: "",
      //     },
      //   ],
      // },
      // {
      //   heading: "",
      //   sub_heading: "",
      //   icon: "",
      //   list: [
      //     {
      //       title: "Challenge",
      //       text: "<p><span>High Bank Charges:</span> Banks charge INR 500 - 1,500 per export bill regularisation, which disproportionately impacts profits on smaller shipments, making export compliance costly for many exporters.</p><p><span>Complex Reconciliation:</span> E-commerce exporters receive payments from multiple platforms like Amazon, Etsy, eBay, and Alibaba, through various modes such as banks, credit cards, payment gateways, and international remittances. Manual reconciliation and extensive documentation consume valuable time and effort for exporters.</p><p><span>Non-Compliance Risks:</span> Provided the stringent regulations for cross-border compliance, the inability to manage these requirements efficiently poses significant operational risks for e-commerce exporters, especially due to limited bandwidth and resources.</p>",
      //     },

      //     {
      //       title: "Solution",
      //       text: "<p><span>Export Compliance Made Effortless:</span> Bluno's digital platform helps e-commerce exporters navigate the complexities of cross-border compliance seamlessly, offering up to 70% cost savings. Now being compliant is a matter of a few clicks with the Bluno platform!</p><p><span>1. Paperless Workflow</span></p><p><span>2. Fully Automated Reconciliation</span></p><p><span>3. Instant Compliance</span></p><p>Bluno empowers Indian e-commerce exporters to focus on scaling their business by eliminating friction in compliance processes. With Bluno, exporting is no longer a tedious task—it’s a smooth journey toward global growth.</p>",
      //     },
      //   ],
      // },
    ],
  },

  ABOUT_US: {
    title: "About Us",
    content: [
      {
        heading: "Mission",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at ",
      },
      {
        heading: "Vision",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non blandit massa enim nec. Scelerisque viverra mauris in aliquam sem. At risus viverra adipiscing at ",
      },
    ],
    icon: BlunoIcon,
  },

  BANNER: {
    header: {
      heading1: "Export compliance made easy,",
      heading2: "just for you",
    },
    bannerText: {
      simpleText: "Say goodbye to",
      colouredText: [
        "complex compliance process",
        "paperwork hassles",
        "multiple bank visits",
        "bank charges & penalties",
        "delayed eBRC",
      ],
    },
    button: {
      name: "Check Pending Export Bills",
      btnType: "s-button--primary",
      link: "https://bluno/login",
      iconUrl: "https://some_icon_url_by strapi",
    },
    trusted: {
      text: "Powered by",
      icons: [
        {
          iconName: "banks",
          icon: yes,
        },
        // {
        //   iconName: "ca",
        //   icon: ca,
        // },
        // {
        //   iconName: "icici",
        //   icon: icici,
        // },
      ],
    },
  },

  ELEVATE: {
    title: "How Bluno works",
    heading: "Elevate your export processes with Bluno",
    timeline: [
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
      {
        header: "Effortless Onboarding",
        moreInfo: "Unlock Efficiency in Minutes",
      },
    ],
  },

  EMPOWER: {
    title:
      "How Bluno <span class='empower--brand-text'> empowers </span> your business?",
    subtitle:
      "You focus on what truly matters—your exports, we handle the rest",
    cards: [
      {
        img: geteBRC,
        heading:
          "Get <span class='empower--brand-text'>eBRC instantly</span> for free",
        content:
          "Comply with the latest compliance requirements and Download eBRC on your system.",
        know_more: "know more",
        transitionDelay: 0.4,
      },
      {
        img: realTime,
        heading:
          "<span class='empower--brand-text'>Real-time tracking</span> of export bills & IRM",
        content:
          "Fully digital and transparent solution with automated and precise matching of transactions.",
        know_more: "know more",
        transitionDelay: 0.8,
      },
      {
        img: boostMargin,
        heading:
          "<span class='empower--brand-text'>Boost export margin</span> & productivity",
        content:
          "Save up to 4% of export turnover and upto 120 hours annually.",
        know_more: "know more",
        transitionDelay: 1.2,
      },
    ],
    button: {
      name: "Get Demo",
      btnType: "primary",
    },
  },

  FOOTER: {
    left: {
      title: {
        logo: blunologo,
        content: "bluno",
      },
      header: "Simplify your export, compete globally",
      address:
        "Hustlehub Tech Park, Somasundarapalya Main Road adjacent 27th Main Road, Sector 2, HSR Layout, Bengaluru 560102",
    },
    right: {
      button: {
        name: "Get Demo",
        btnType: "primary",
        link: "https://bluno/get-a-demo",
      },
      contactTitle: "Contact Us",
      contactEmail: "contact@blunoit.com",
      socialLinks: [
        {
          icon: InstagramIcon,
          altText: "Instagram",
          redirectionLink: "",
        },
        {
          icon: FacebookIcon,
          altText: "Facebook",
          redirectionLink: "",
        },
        {
          icon: TwitterIcon,
          altText: "Twitter",
          redirectionLink: "",
        },
      ],
    },
    copyright: {
      rights: "Copyright © 2024 Bluno. All rights reserved.",
      privacy: "Privacy Policy",
      partition: "|",
      terms: "Terms of Use",
    },
  },

  NAVBAR: {
    companyName: "bluno",
    contents: [
      {
        name: "Products",
        type: "list",
        list: [
          {
            name: "Product 1",
            link: "https:///link/product1",
          },
          {
            name: "Product 2",
            link: "https:///link/product2",
          },
          {
            name: "Product 3",
            link: "https:///link/product3",
          },
        ],
      },
      {
        name: "Use Cases",
        type: "list",
        list: [
          {
            name: "Use Case 1",
            link: "https:///link/usecase1",
          },
          {
            name: "Use Case 2",
            link: "https:///link/usecase2",
          },
          {
            name: "Use Case 3",
            link: "https:///link/usecase3",
          },
        ],
      },
      {
        name: "Blog",
        type: "link",
        link: "https://bluno/blog",
      },
      {
        name: "About",
        type: "link",
        link: "https://bluno/about",
      },
    ],
    button1Content: {
      name: "Get Demo",
      btnType: "primary",
      link: "https://bluno/get-a-demo",
    },
  },

  POWERED_BY: {
    title: "Powered by",
    imgLink: {
      img1: "img1Link",
      img2: "img2Link",
      img3: "img3Link",
      img4: "img4Link",
      img5: "img5Link",
    },
    secureData: "You data is 100% safe and secured by latest technology",
  },

  TESTIMONIAL: {
    title: "Testimonial",
    header: "What Bluno <span>users say</span>",
    slides: [
      {
        img: user1,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "primary",
      },
      {
        img: user2,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "secondary",
      },
      {
        img: user3,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "tertiary",
      },
      {
        img: user2,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "secondary",
      },
      {
        img: user1,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "primary",
      },
      {
        img: user3,
        title: "Lorem ipsum dolor",
        header: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        themeColor: "tertiary",
      },
    ],
  },

  COMING_SOON: {
    left: {
      icon: BlunoIcon,
      title: "bluno",
      header: "Redefining the journey of",
      coloredHeader: "small & medium business exporters",
    },
    right: {
      title: "COMING SOON",
      header: "Join today for early access",
      inputs: {
        email: {
          label: "Email ID",
          placeHolder: "Enter your email ID",
        },
        phone: {
          label: "Mobile Number",
          countryCode: "+91",
          placeHolder: "Enter your mobile number",
        },
      },
      button: {
        name: "Get Early Access",
        btnType: "primary",
        link: "https://bluno/get-early-access",
      },
    },
  },

  THANK_YOU: {
    img: handshake,
    header: "Thank you for showing your interest!",
    content: "We will be in touch with you soon.",
    logoIcon: BlunoIcon,
    logoTitle: "bluno",
  },
};

export const DEMO_FORM = {
  title: "<span>Request a free demo</span>",
  form_data: [
    {
      id: 1,
      slug: "company_name",
      type: "input",
      sub_type: "text",
      label: "Company Name <span>*</span>",
      placeholder: "Company name",
      column: "1/3",
      row: "1",
    },
    {
      id: 2,
      slug: "mobile_number",
      type: "input",
      sub_type: "tel",
      label: "Mobile Number <span>*</span>",
      placeholder: "Mobile number",
      column: "1/3",
      row: "2",
    },
    {
      id: 3,
      slug: "email_address",
      type: "input",
      sub_type: "email",
      label: "Email ID (as per IEC) <span>*</span>",
      placeholder: "Email ID",
      column: "1/3",
      row: "3",
    },
    {
      id: 4,
      slug: "company_banking_partners",
      type: "multi_select_dropdown",
      label: "Select Banking Partners (max 3)",
      placeholder: "Select Banking Partners (max 3)",
      options: [
        {
          label: "State Bank of India",
          value: "SBIN",
          icon: "https://static.bluno.in/banks/sbi-icon.png",
        },
        {
          label: "Yes Bank",
          value: "YESB",
          icon: "https://static.bluno.in/banks/yes-bank-icon.png",
        },
        {
          label: "HDFC Bank",
          value: "HDFC",
          icon: "https://static.bluno.in/banks/hdfc-icon.png",
        },
        {
          label: "ICICI Bank",
          value: "ICIC",
          icon: "https://static.bluno.in/banks/icici-icon.png",
        },
        {
          label: "Federal Bank",
          value: "FDRL",
          icon: "https://static.bluno.in/banks/federal-icon.png",
        },
        {
          label: "Kotak Mahindra Bank",
          value: "KKBK",
          icon: "https://static.bluno.in/banks/kotak-icon.png",
        },
        {
          label: "Axis Bank",
          value: "UTIB",
          icon: "https://static.bluno.in/banks/axis-icon.png",
        },
        {
          label: "Punjab National Bank",
          value: "PUNB",
          icon: "https://static.bluno.in/banks/punjab-national-bank-icon.png",
        },
        {
          label: "Bank of Baroda",
          value: "BARB",
          icon: "https://static.bluno.in/banks/bank-of-baroda-icon.png",
        },
        { label: "Others", value: "others" },

        // { label: "ABHYUDAYA COOPERATIVE BANK LIMITED", value: "ABHY" },
        // { label: "THE ADARSH COOPERATIVE URBAN BANK LIMITED", value: "ADBK" },
        // {
        //   label:
        //     "THE AKOLA-WASHIM DISTRICT CENTRAL COOPERATIVE BANK LTD., AKOLA",
        //   value: "ADCC",
        // },
        // {
        //   label: "THE AHMEDNAGAR DISTRICT CENTRAL COOPERATIVE BANK LTD",
        //   value: "AHDC",
        // },
        // { label: "AIRTEL PAYMENTS BANK LIMITED", value: "AIRP" },
        // { label: "THE AJARA URBAN CO OP BANK LTD AJARA", value: "AJAR" },
        // { label: "AMBARNATH JAIHIND COOP BANK LTD AMBARNATH", value: "AJHC" },
        // { label: "AKOLA JANATA COMMERCIAL COOPERATIVE BANK", value: "AKJB" },
        // { label: "AHMEDABAD MERCANTILE COOPERATIVE BANK", value: "AMCB" },
        // { label: "AHMEDNAGAR MERCHANTS COOP BANK LTD", value: "AMDN" },
        // { label: "Australia and New Zealand Banking Group", value: "ANZB" },
        // {
        //   label: "THE ANDHRA PRADESH STATE COOPERATIVE BANK LIMITED",
        //   value: "APBL",
        // },
        // { label: "ANDHRA PRAGATHI GRAMEENA BANK", value: "APGB" },
        // { label: "ANDHRA PRADESH GRAMEENA VIKAS BANK", value: "APGV" },
        // {
        //   label: "THE A.P. MAHESH COOPERATIVE URBAN BANK LIMITED",
        //   value: "APMC",
        // },
        // { label: "ARVIND SAHAKARI BANK LTD", value: "ARBL" },
        // { label: "APNA SAHAKARI BANK LIMITED", value: "ASBL" },
        // { label: "AU SMALL FINANCE BANK LIMITED", value: "AUBL" },
        // { label: "ALMORA URBAN COOPERATIVE BANK LIMITED", value: "AUCB" },
        // { label: "BASSEIN CATHOLIC COOPERATIVE BANK LIMITED", value: "BACB" },
        // { label: "THE BARAMATI SAHAKARI BANK LTD", value: "BARA" },
        // { label: "Barclays Bank", value: "BARC" },
        // { label: "Bank of Bahrain and Kuwait BSC", value: "BBKM" },
        // { label: "BHARAT COOPERATIVE BANK MUMBAI LIMITED", value: "BCBM" },
        // { label: "Bank of Ceylon", value: "BCEY" },
        // { label: "Bank of China Limited", value: "BCHN" },
        // { label: "Bandhan Bank Ltd.", value: "BDBL" },
        // { label: "Bank of India", value: "BKID" },
        // { label: "BOMBAY MERCANTILE COOPERATIVE BANK LTD", value: "BMCB" },
        // { label: "BNP Paribas", value: "BNPA" },
        // { label: "BHAGINI NIVEDITA SAHAKARI BANK LTD PUNE", value: "BNSB" },
        // { label: "Bank of America", value: "BOFA" },
        // { label: "MUFG Bank", value: "BOTM" },
        // { label: "THE BURDWAN CENTRAL CO OPERATIVE BANK LTD", value: "BUCB" },
        // { label: "Catholic Syrian Bank", value: "CBS" },
        // { label: "Central Bank of India", value: "CBIN" },
        // { label: "CITIZEN CREDIT COOPERATIVE BANK LIMITED", value: "CCBL" },
        // { label: "JP Morgan Chase Bank", value: "CHAS" },
        // { label: "Citi Bank", value: "CITI" },
        // { label: "City Union Bank", value: "CIUB" },
        // { label: "CAPITAL SMALL FINANCE BANK LIMITED", value: "CLBL" },
        // { label: "Canara Bank", value: "CNRB" },
        // { label: "COASTAL LOCAL AREA BANK LTD", value: "COAS" },
        // { label: "THE COSMOS CO OPERATIVE BANK LIMITED", value: "COSB" },
        // { label: "UBS AG", value: "CRES" },
        // { label: "CHHATTISGARH RAJYA GRAMIN BANK", value: "CRGB" },
        // {
        //   label: "Credit Agricole Corporate and Investment Bank",
        //   value: "CRLY",
        // },
        // {
        //   label:
        //     "SHRI CHHATRAPATI RAJASHRI SHAHU URBAN COOPERATIVE BANK LIMITED",
        //   value: "CRUB",
        // },
        // { label: "CSB BANK LIMITED", value: "CSBK" },
        // { label: "CTBC Bank", value: "CTCB" },
        // { label: "DBS Bank India Limited", value: "DBSS" },
        // { label: "Development Credit Bank", value: "DCBL" },
        // { label: "DARUSSALAM CO OPERATIVE URBAN BANK LTD", value: "DCUB" },
        // {
        //   label: "DEOGIRI NAGARI SAHAKARI BANK LTD. AURANGABAD",
        //   value: "DEOB",
        // },
        // { label: "Deutsche Bank", value: "DEUT" },
        // {
        //   label: "DEPOSIT INSURANCE AND CREDIT GUARANTEE CORPORATION",
        //   value: "DICG",
        // },
        // { label: "THE DELHI STATE COOPERATIVE BANK LIMITED", value: "DLSC" },
        // { label: "Dhanlaxmi Bank", value: "DLXB" },
        // { label: "DMK JAOLI BANK", value: "DMKJ" },
        // { label: "DOMBIVLI NAGARI SAHAKARI BANK LIMITED", value: "DNSB" },
        // { label: "Doha Bank Q.P.S.C", value: "DOHB" },
        // {
        //   label: "DURGAPUR STEEL PEOPLES CO-OPERATIVE BANK LTD",
        //   value: "DURG",
        // },
        // { label: "Emirates NBD Bank P.J.S.C", value: "EBIL" },
        // { label: "EXPORT IMPORT BANK OF INDIA", value: "EIBI" },
        // { label: "EQUITAS SMALL FINANCE BANK LIMITED", value: "ESFB" },
        // { label: "ESAF SMALL FINANCE BANK LIMITED", value: "ESMF" },

        // { label: "FINO PAYMENTS BANK", value: "FINO" },
        // { label: "FirstRand Bank", value: "FIRN" },
        // { label: "FINCARE SMALL FINANCE BANK LTD", value: "FSFB" },
        // { label: "THE GREATER BOMBAY COOPERATIVE BANK LIMITED", value: "GBCB" },
        // {
        //   label: "THE GADCHIROLI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        //   value: "GDCB",
        // },
        // { label: "THE GUJARAT STATE COOPERATIVE BANK LIMITED", value: "GSCB" },
        // { label: "GUARDIAN SOUHARDA SAHAKARI BANK NIYAMITA", value: "GSSB" },
        // { label: "HARYANA STATE COOPERATIVE BANK", value: "HARC" },
        // { label: "THE HASTI COOP BANK LTD", value: "HCBL" },

        // { label: "HIMACHAL PRADESH STATE COOPERATIVE BANK LTD", value: "HPSC" },
        // { label: "HSBC BANK", value: "HSBC" },
        // { label: "HUTATMA SAHAKARI BANK LTD", value: "HUSB" },
        // { label: "Woori Bank", value: "HVBK" },
        // { label: "PT Bank Maybank Indonesia TBK", value: "IBBK" },
        // { label: "IDBI Bank", value: "IBKL" },
        // { label: "Industrial Bank of Korea", value: "IBKO" },
        // { label: "Industrial and Commercial Bank of China", value: "ICBK" },
        // { label: "IDFC Bank", value: "IDFB" },
        // { label: "Indian Bank", value: "IDIB" },
        // { label: "IndusInd Bank", value: "INDB" },
        // { label: "Indian Overseas Bank", value: "IOBA" },
        // { label: "INDIA POST PAYMENT BANK", value: "IPOS" },
        // { label: "IRINJALAKUDA TOWN CO OPERATIVE BANK LTD", value: "ITBL" },
        // { label: "Jammu & Kashmir Bank", value: "JAKA" },
        // { label: "JANASEVA SAHAKARI BANK LIMITED", value: "JANA" },
        // { label: "JANASEVA SAHAKARI BANK BORIVLI LIMITED", value: "JASB" },
        // { label: "JIO PAYMENTS BANK LIMITED", value: "JIOP" },
        // { label: "JALGAON JANATA SAHAKARI BANK LIMITED", value: "JJSB" },
        // {
        //   label: "THE JALGAON PEOPELS COOPERATIVE BANK LIMITED",
        //   value: "JPCB",
        // },
        // { label: "JANAKALYAN SAHAKARI BANK LIMITED", value: "JSBL" },
        // { label: "JANATA SAHAKARI BANK LIMITED", value: "JSBP" },
        // { label: "JANA SMALL FINANCE BANK LTD", value: "JSFB" },
        // { label: "JANATHA SEVA COOPERATIVE BANK LTD", value: "JTSC" },
        // { label: "THE KANGRA CENTRAL COOPERATIVE BANK LIMITED", value: "KACE" },
        // {
        //   label: "KALLAPPANNA AWADE ICHALKARANJI JANATA SAHAKARI BANK LIMITED",
        //   value: "KAIJ",
        // },
        // { label: "THE KANGRA COOPERATIVE BANK LIMITED", value: "KANG" },
        // { label: "Karnataka Bank", value: "KARB" },
        // { label: "Kookmin Bank", value: "KBKB" },
        // { label: "KALUPUR COMMERCIAL COOPERATIVE BANK", value: "KCCB" },
        // { label: "THE KALYAN JANATA SAHAKARI BANK LTD.", value: "KJSB" },
        // { label: "KERALA GRAMIN BANK", value: "KLGB" },
        // { label: "THE KURMANCHAL NAGAR SAHAKARI BANK LIMITED", value: "KNSB" },
        // { label: "KEB Hana Bank", value: "KOEX" },
        // { label: "THE KOLHAPUR URBAN CO OP BANK LTD", value: "KOLH" },
        // { label: "THE KERALA STATE CO OPERATIVE BANK LTD", value: "KSBK" },
        // {
        //   label: "THE KARANATAKA STATE COOPERATIVE APEX BANK LIMITED",
        //   value: "KSCB",
        // },
        // { label: "THE KARAD URBAN COOPERATIVE BANK LIMITED", value: "KUCB" },
        // { label: "Karur Vysya Bank", value: "KVBL" },
        // { label: "KARNATAKA VIKAS GRAMEENA BANK", value: "KVGB" },
        // { label: "Bank of Maharashtra", value: "MAHB" },
        // { label: "MAHARASHTRA GRAMIN BANK", value: "MAHG" },
        // { label: "THE MEGHALAYA COOPERATIVE APEX BANK LTD", value: "MCAB" },
        // {
        //   label: "GS MAHANAGAR CO-OPERATIVE BANK LIMITED,MUMBAI",
        //   value: "MCBL",
        // },
        // { label: "MODEL COOPERATIVE BANK LTD", value: "MDBK" },
        // {
        //   label: "THE MUMBAI DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        //   value: "MDCB",
        // },
        // { label: "Mizuho Bank Ltd.", value: "MHCB" },
        // { label: "MAHESH SAHAKARI BANK LTD PUNE", value: "MSBL" },
        // { label: "MAHARASHTRA STATE COOPERATIVE BANK", value: "MSCI" },
        // { label: "Mashreqbank P.S.C", value: "MSHQ" },
        // { label: "THE MUSLIM COOPERATIVE BANK LTD", value: "MSLM" },
        // { label: "THE MEHSANA URBAN COOPERATIVE BANK", value: "MSNU" },
        // { label: "THE MUNICIPAL COOPERATIVE BANK LIMITED", value: "MUBL" },
        // { label: "SIR M VISVESVARAYA CO OPERATIVE BANK LTD", value: "MVCB" },
        // { label: "First Abu Dhabi Bank PJSC", value: "NBAD" },
        // {
        //   label: "NATIONAL BANK FOR AGRICULTURE AND RURAL DEVELOPMENT",
        //   value: "NBRD",
        // },
        // {
        //   label: "THE NILAMBUR CO OPERATIVE URBAN BANK LTD NILAMBUR",
        //   value: "NCUB",
        // },
        // { label: "NORTH EAST SMALL FINANCE BANK LIMITED", value: "NESF" },
        // { label: "NAGPUR NAGARIK SAHAKARI BANK LIMITED", value: "NGSB" },
        // { label: "NEW INDIA COOPERATIVE BANK LIMITED", value: "NICB" },
        // { label: "NAV JEEVAN CO OP BANK LTD", value: "NJBK" },
        // { label: "NKGSB COOPERATIVE BANK LIMITED", value: "NKGS" },
        // {
        //   label: "THE NASIK MERCHANTS COOPERATIVE BANK LIMITED",
        //   value: "NMCB",
        // },
        // { label: "NUTAN NAGARIK SAHAKARI BANK LIMITED", value: "NNSB" },
        // { label: "Bank of Nova Scotia", value: "NOSC" },
        // { label: "NSDL PAYMENTS BANK LIMITED", value: "NSPB" },
        // { label: "Nainital Bank", value: "NTBL" },
        // { label: "THE NAVNIRMAN COOPERATIVE BANK LIMITED", value: "NVNM" },
        // { label: "THE ODISHA STATE CO-OPERATIVE BANK LTD", value: "ORCB" },
        // { label: "G P PARSIK BANK", value: "PJSB" },
        // { label: "KARNATAKA GRAMIN BANK", value: "PKGB" },
        // { label: "PRIME COOPERATIVE BANK LIMITED", value: "PMEC" },
        // { label: "PAVANA SAHAKARI BANK LTD", value: "PSBL" },
        // { label: "Punjab & Sind Bank", value: "PSIB" },
        // {
        //   label: "THE PANDHARPUR URBAN CO OP. BANK LTD. PANDHARPUR",
        //   value: "PUCB",
        // },
        // { label: "THE PUSAD URBAN COOPERATIVE BANK LTD PUSAD", value: "PUSD" },
        // { label: "PAYTM PAYMENTS BANK LTD", value: "PYTM" },
        // { label: "Qatar National Bank (Q.P.S.C)", value: "QNBA" },
        // { label: "Rabobank International", value: "RABO" },
        // { label: "Ratnakar Bank (RBL)", value: "RATN" },
        // { label: "RESERVE BANK OF INDIA", value: "RBIN" },
        // { label: "RESERVE BANK OF INDIA", value: "RBIP" },
        // { label: "RESERVE BANK OF INDIA", value: "RBIS" },
        // {
        //   label: "RAJNANDGAON DISTRICT CENTRAL COOPERATIVE BANK LTD",
        //   value: "RDCB",
        // },
        // { label: "RAJASTHAN MARUDHARA GRAMIN BANK", value: "RMGB" },
        // { label: "RAJKOT NAGRIK SAHAKARI BANK LIMITED", value: "RNSB" },
        // { label: "RAJARAMBAPU SAHAKARI BANK LIMITED", value: "RRBP" },
        // { label: "RAJGURUNAGAR SAHAKARI BANK LIMITED", value: "RSBL" },
        // {
        //   label: "THE RAJASTHAN STATE COOPERATIVE BANK LIMITED",
        //   value: "RSCB",
        // },
        // { label: "RAJARSHI SHAHU SAHAKARI BANK LTD  PUNE", value: "RSSB" },
        // { label: "Sber Bank", value: "SABR" },
        // { label: "SAPTAGIRI GRAMEENA BANK", value: "SAGB" },
        // { label: "SANT SOPANKAKA SAHAKARI BANK LTD", value: "SANT" },

        // { label: "SAMARTH SAHAKARI BANK LTD", value: "SBLS" },
        // { label: "Standard Chartered Bank", value: "SCBL" },
        // { label: "THE SURAT DISTRICT COOPERATIVE BANK LIMITED", value: "SDCB" },
        // {
        //   label: "THE SATARA DISTRICT CENTRAL COOPERATIVE BANK LTD",
        //   value: "SDCE",
        // },
        // { label: "SREE CHARAN SOUHARDHA CO OPERATIVE BANK LTD", value: "SECB" },
        // { label: "SAURASHTRA GRAMIN BANK", value: "SGBA" },
        // { label: "Shinhan Bank", value: "SHBK" },
        // { label: "South Indian Bank", value: "SIBL" },
        // {
        //   label: "THE SINDHUDURG DISTRICT CENTRAL CO-OPERATIVE BANK LTD.",
        //   value: "SIDC",
        // },
        // { label: "SOLAPUR JANATA SAHAKARI BANK LIMITED", value: "SJSB" },
        // { label: "SHREE KADI NAGARIK SAHAKARI BANK LIMITED", value: "SKNB" },
        // { label: "SHIKSHAK SAHAKARI BANK LIMITED", value: "SKSB" },
        // { label: "Sumitomo Mitsui Banking Corporation", value: "SMBC" },
        // { label: "SHIVALIK SMALL FINANCE BANK LIMITED", value: "SMCB" },
        // { label: "SMRITI NAGRIK SAHAKARI BANK MARYADIT", value: "SMNB" },
        // {
        //   label: "SARASPUR NAGRIK CO OPERATIVE BANK LTD SARASPUR",
        //   value: "SNBK",
        // },
        // { label: "Societe Generale", value: "SOGE" },
        // { label: "THE SURATH PEOPLES COOPERATIVE BANK LIMITED", value: "SPCB" },
        // { label: "SARASWAT COOPERATIVE BANK LIMITED", value: "SRCB" },
        // { label: "SBM Bank (India)", value: "STCB" },
        // { label: "SURAT NATIONAL COOPERATIVE BANK LIMITED", value: "SUNB" },
        // { label: "SURYODAY SMALL FINANCE BANK LIMITED", value: "SURY" },
        // { label: "SUCO SOUHARDHA SAHAKARI BANK LTD", value: "SUSB" },
        // { label: "SUTEX COOPERATIVE BANK LIMITED", value: "SUTB" },
        // { label: "THE SHAMRAO VITHAL COOPERATIVE BANK", value: "SVCB" },
        // { label: "SHRI VEERSHAIV CO OP BANK LTD", value: "SVSH" },
        // { label: "THE AKOLA URBAN COOPERATIVE BANK LIMITED", value: "TAUB" },
        // {
        //   label: "THE BANASKANTHA MERCANTILE COOPERATIVE BANK LTD",
        //   value: "TBMC",
        // },
        // { label: "THE THANE BHARAT SAHAKARI BANK LIMITED", value: "TBSB" },
        // { label: "THE COOPERATIVE BANK OF RAJKOT LIMITED", value: "TCBR" },
        // {
        //   label: "THE THANE DISTRICT CENTRAL COOPERATIVE BANK LIMITED",
        //   value: "TDCB",
        // },
        // {
        //   label: "TUMKUR GRAIN MERCHANTS COOPERATIVE BANK LIMITED",
        //   value: "TGMB",
        // },
        // { label: "TJSB SAHAKARI BANK LTD", value: "TJSB" },
        // { label: "Tamilnad Mercantile Bank", value: "TMBL" },
        // { label: "THE MALAD SAHAKARI BANK LTD", value: "TMSB" },
        // { label: "THE NAWANAGAR COOPERATIVE BANK LTD", value: "TNCB" },
        // { label: "THE TAMIL NADU STATE APEX COOPERATIVE BANK", value: "TNSC" },
        // { label: "THE PUNJAB STATE COOPERATIVE BANK LTD", value: "TPSC" },
        // { label: "TELANGANA STATE COOP APEX BANK", value: "TSAB" },
        // { label: "SATARA SAHAKARI BANK LTD", value: "TSSB" },
        // { label: "TTC CO-OPERATIVE BANK LIMITED", value: "TTCB" },
        // { label: "Union Bank of India", value: "UBIN" },
        // { label: "UCO Bank", value: "UCBA" },
        // { label: "URBAN CO OPERATIVE BANK LTD BAREILLY", value: "UCLB" },
        // { label: "UJJIVAN SMALL FINANCE BANK LIMITED", value: "UJVN" },
        // { label: "UNITY SMALL FINANCE BANK LIMITED", value: "UNBA" },
        // { label: "United Overseas Bank Limited", value: "UOVB" },
        // { label: "UTTAR PRADESH COOPERATIVE BANK", value: "UPCB" },
        // {
        //   label:
        //     "THE URBAN CO OPERATIVE BANK LTD NO ONE SEVEN FIVE EIGHT PERINTHALMANNA",
        //   value: "URBN",
        // },
        // { label: "UTTARAKHAND STATE COOPERATIVE BANK LIMITED", value: "USCB" },
        // { label: "UTKARSH SMALL FINANCE BANK", value: "UTKS" },
        // { label: "THE UDAIPUR URBAN CO OPERATIVE BANK LTD", value: "UUCB" },
        // { label: "THE VARACHHA COOPERATIVE BANK LIMITED", value: "VARA" },
        // { label: "VASAI JANATA SAHAKARI BANK LTD", value: "VASJ" },
        // { label: "THE VIJAY CO OPERATIVE BANK LIMITED", value: "VCOB" },
        // { label: "VIKAS SOUHARDA CO OPERATIVE BANK LIMITED", value: "VSBA" },
        // { label: "THE VISHWESHWAR SAHAKARI BANK LIMITED", value: "VSBL" },
        // { label: "JSC VTB Bank", value: "VTBJ" },
        // { label: "VASAI VIKAS SAHAKARI BANK LIMITED", value: "VVSB" },
        // { label: "THE WEST BENGAL STATE COOPERATIVE BANK", value: "WBSC" },
        // { label: "THE ZOROASTRIAN COOPERATIVE BANK LIMITED", value: "ZCBL" },
        // { label: "ZILA SAHAKRI BANK LIMITED GHAZIABAD", value: "ZSBL" },
        // { label: "Sonali Bank", value: "SONB" },
        // { label: "NongHyup Bank", value: "NONB" },
        // { label: "NatWest Markets PLC", value: "NATWE" },
        // {
        //   label: "Hong Kong and Shanghai Banking Corporation Limited",
        //   value: "HKSB",
        // },
        // { label: "American Express Banking Corporation", value: "AMEX" },
        // { label: "AB Bank PLC", value: "ABBA" },
      ],
      column: "1/3",
      row: "4",
      isMultipleSelect: true,
      maxAllowedSelections: 3,
      // column_mobile: "1/3",
      // row_mobile: "5",
    },
    {
      id: 5,
      slug: "other_banks",
      type: "input",
      sub_type: "text",
      label: "Other banks <span>*</span>",
      placeholder: "Other banks",
      column: "1/3",
      row: "5",
    },
    {
      id: 6,
      slug: "client_type",
      type: "checkbox",
      label: "Are you into <span>(Optional)</span>",
      column: "1/3",
      row: "6",
      options: [
        {
          text: "Export",
          slug: "export",
          name: "client_type",
        },
        {
          text: "Import",
          slug: "import",
          name: "client_type",
        },
      ],
    },
    // {
    //   id: 5,
    //   slug: "export_turnover_currency",
    //   type: "dropdown",
    //   label: "Annual export turnover range<span> (optional)</span>",
    //   column: "1/3",
    //   row: "4",
    //   column_mobile: "1/3",
    //   row_mobile: "5",
    //   options: [
    //     { label: "INR", value: "inr" },
    //     { label: "USD", value: "usd" },
    //     ,
    //   ],
    // },
    // {
    //   id: 6,
    //   slug: "export_turnover_range",
    //   type: "slider",
    //   column: "1/3",
    //   row: "5",
    //   column_mobile: "1/3",
    //   row_mobile: "6",
    //   step: 1,
    //   min: 0,
    //   max: 500,
    //   marks: [
    //     {
    //       value: 2,
    //       text1: "Upto 1",
    //       text2: "crore",
    //     },
    //     {
    //       value: 100,
    //       text1: "5",
    //       text2: "crore",
    //     },
    //     {
    //       value: 200,
    //       text1: "25",
    //       text2: "crore",
    //     },
    //     {
    //       value: 300,
    //       text1: "50",
    //       text2: "crore",
    //     },
    //     {
    //       value: 400,
    //       text1: "100",
    //       text2: "crore",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above",
    //       text2: "500 crore",
    //     },
    //   ],
    //   marksUSD: [
    //     {
    //       value: 2,
    //       text1: "Upto 100",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 102,
    //       text1: "500",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 200,
    //       text1: "1",
    //       text2: "million",
    //     },
    //     {
    //       value: 300,
    //       text1: "5",
    //       text2: "million",
    //     },
    //     {
    //       value: 400,
    //       text1: "25",
    //       text2: "million",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above 50",
    //       text2: "million",
    //     },
    //   ],
    // },
    {
      id: 7,
      slug: "agreement",
      column: "1/3",
      row: "7",
      type: "text",
      text: 'By continuing you agree to our <a href="/privacy-policy" target="_parent">privacy policy</a> and <a href="/terms-of-service" target="_parent">terms of service</a>',
    },
    {
      id: 8,
      slug: "request_demo",
      column: "1/3",
      row: "8",
      type: "button",
      button_label: "Book Demo",
      button_type: "primary",
      icon: requestDemo,
      iconAlignment: "left",
    },
  ],
};

export const BLUNO_VALUES = {
  title: "Our Values",
  subtitle: "You focus on what truly matters—your exports, we handle the rest",
  values: [
    {
      title: "Trust & Integrity",
      subtitle: "define us.",
      icon: "https://static.bluno.in/landing/value-1.webp",
      description:
        "They represent our commitment to transparency, accountability, and ethical practices - so our customers can trust us throughout their journey",
    },
    {
      title: "Innovation",
      subtitle: "drives us.",
      icon: "https://static.bluno.in/landing/value-2.webp",
      description:
        "By embracing cutting-edge technology,  we are committed to pioneering smarter, faster, and more efficient trade compliance solutions.",
    },
    {
      title: "Customer Success",
      subtitle: "inspires us.",
      icon: "https://static.bluno.in/landing/value-3.webp",
      description:
        "We measure our success by the impact we create for our customers. Each milestone you achieve drives us pushing boundaries and delivering excellence.",
    },
  ],
  button: [
    {
      button_label: "Get Free Demo",
      button_type: "primary",
      deeplink: null,
      icon: null,
    },
  ],
};

export const BLUNO_TEAM = {
  title: "Our Team/Founder Profile",
  team: [
    {
      profile_icon: "https://static.bluno.in/landing/rajeev.webp",
      linkedin: {
        icon: "https://static.bluno.in/sm/social_icon_linkedin_hover.png",
        deeplink: "https://in.linkedin.com/in/rajeev-nikumbh-a19bb425",
      },
      name: "Rajeev Nikumbh,",
      designation: "Co-founder",
      description: [
        {
          text: "2+ years of banking background with expertise in structuring of bespoke financial instruments",
        },
        {
          text: "Executed 10+ deals worth > US$ 1bn across multiple sectors including cross-border deals.",
        },
      ],
      experience: [
        {
          icon: "https://static.bluno.in/landing/iit-kgp.webp",
        },
        {
          icon: "https://static.bluno.in/landing/barclays-bank.webp",
        },
        {
          icon: "https://static.bluno.in/landing/db-bank.webp",
        },
      ],
    },
    {
      profile_icon: "https://static.bluno.in/landing/mohit.webp",
      linkedin: {
        icon: "https://static.bluno.in/sm/social_icon_linkedin_hover.png",
        deeplink: "https://in.linkedin.com/in/mohit-bhatia-81701784",
      },
      name: "Mohit Bhatia,",
      designation: "Co-founder",
      description: [
        {
          text: "Accomplished technical architect with a proven track record in designing and implementing robust, secure and scalable systems.",
        },
        {
          text: "Comprehensive understanding of payments ecosystem of multiple geographies.",
        },
      ],
      experience: [
        {
          icon: "https://static.bluno.in/landing/iit-guwahati.webp",
        },
        {
          icon: "https://static.bluno.in/landing/ola.webp",
        },
        {
          icon: "https://static.bluno.in/landing/zolve.webp",
        },
      ],
    },
  ],
};

export const ABOUT_US = {
  title: "About Us",
  content: [
    {
      heading: "Transform every business into an export powerhouse. ",
      color: "#046CB8",
      paragrapgh: [
        {
          text: "At Bluno, we are committed to transforming every local business into a thriving export house by making international trade borderless while ensuring compliance",
        },
      ],
    },
    {
      heading: "Mission",
      color: "#161616",
      paragrapgh: [
        {
          text: "At Bluno, our mission is to empower exporters and importers to unlock their global potential and thrive in the competitive export market.",
        },
        {
          text: "By leveraging advanced technology and industry expertise, we deliver fully digital, seamless, and cost-effective trade compliance solutions that ensure error-free, future-focused operations. ",
        },
        {
          text: "With Bluno, businesses can minimize costs, maximize profits, and build lasting relationships with global buyers. Whether scaling operations or exploring new markets, we are your trusted partner in achieving trade success.",
        },
      ],
    },
  ],
  icon: "https://static.bluno.in/landing/bluno-fly.webp",
};
